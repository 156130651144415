$(document).ready(function() {
	
  const headspace = Headspace(document.querySelector('header'));

	$("main").css('margin-top', $('header').outerHeight());
  
  if (window.matchMedia('(min-width: 768px)').matches) {
    ScrollOut({
      targets: 'h1, h2, h3, h4, h5, h6, p',
      once: true,
      threshold: 0.4
    });
  }

   //Mobile menu
   $(".MenuBar").click(function(e){
    e.preventDefault();
    $("#header .NavContainer").addClass("Active_Nav");
  });
  $(".MenuOverlay").click(function(e){
    e.preventDefault();
    $("#header .NavContainer").removeClass("Active_Nav");
  });

  //Check Screen size
  let checkScrennSize = () => {
    if (window.matchMedia('(max-width: 991px)').matches) {
        $(".menu-item-has-children>a").append('<span></span>');
    }
    $(".menu-item-has-children").find('a>span').click(function(e){
      e.preventDefault();
      $(this).parents('a').parent().toggleClass("Active_MobileDropdown");
    });
  }
  checkScrennSize();

  $('.videoSlide').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: '.prevSlide',
    nextArrow: '.nextSlide',
    autoplay: true,
		autoplaySpeed: 5000,
		cssEase: 'ease-in-out',
    fade: false
  });

  if (window.matchMedia('(min-width: 768px)').matches) {
    $(".hideAndShow").hide();
    $("#loadMorePoints").on("click", function (e) {
        e.preventDefault();
        var txt = $(".hideAndShow").is(':visible') ? 'Read More <svg class="arrowDown"><use xlink:href="assets/img/webidigital-sprite.svg#icon-circle-down"></use></svg>' : 'Read Less <svg class="arrowUp"><use xlink:href="assets/img/webidigital-sprite.svg#icon-circle-down"></use></svg>';
        $('.hideAndShow').slideToggle('slow');
        $("#loadMorePoints").html(txt);
    });
  }

  $(window).scroll(function() {
		400 < $(this).scrollTop() ? $("#scroll").fadeIn() : $("#scroll").fadeOut()
	});
	
	$("#scroll").click(function() {
		return $("html, body").animate({
			scrollTop: 0
		}, 600), !1
	});

  $(".subscriberEmail").validate({
    rules: {
      email: {
        required: true,
        email: true
      }
    },
    messages: {
      email: "Please enter a valid email address"
    },
    submitHandler: function(form) {
      $.ajax({
        url: "subscribe-mail.php",
        // dataType: "JSON",
        type: "POST",
        data: $(form).serialize(),
        // beforeSend: function(xhr){
        //   $('#subscriberSubmit').val('SENDING...');
        // },
        success: function(response) {
          if (response) {
            console.log(response, "response");
            $('.subscriberEmail').append('<div class="success FormMessage">Thanks for subscribing!</div>');
            $(form)[0].reset();
          }
        },
        error: function(err) {
          console.log(err, "error");
          $('.subscriberEmail').append('<div class="error FormMessage">Please try again</div>');
          $(form)[0].reset();
        }
      });
    }
  });

  $(".contactForm").validate({
    rules: {
      email: {
        required: true,
        email: true
      }
    },
    // messages: {
    //   email: "Please enter a valid email address"
    // },
    submitHandler: function(form) {
      $.ajax({
        url: "contact-mail.php",
        // dataType: "JSON",
        type: "POST",
        data: $(form).serialize(),
        // beforeSend: function(xhr){
        //   $('#subscriberSubmit').val('SENDING...');
        // },
        success: function(response) {
          if (response) {
            console.log(response, "response");
            $('.contactForm').append('<div class="success FormMessage">Thanks for subscribing!</div>');
            $(form)[0].reset();
          }
        },
        error: function(err) {
          console.log(err, "error");
          $('.contactForm').append('<div class="error FormMessage">Please try again</div>');
          $(form)[0].reset();
        }
      });
    }
  });

  // Vanilla Javascript
  var input = document.querySelector("#phone");
  var errorMsg = document.querySelector("#error-msg");
  var validMsg = document.querySelector("#valid-msg");
  if (input != null) {
    // here, the index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    var iti = window.intlTelInput(input,({
      initialCountry: "auto",
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          success(countryCode);
        });
      },
      separateDialCode: true,
      utilsScript: "../components/intltelinput/utils.js",
    }));

    $(document).ready(function() {
        $('.iti__flag-container').click(function() { 
          var countryCode = $('.iti__selected-flag').attr('title');
          var countryCode = countryCode.replace(/[^0-9]/g,'')
          $('#phone').val("");
          $('#phone').val("+"+countryCode+" "+ $('#phone').val());
      });
    });

    var reset = function() {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
      validMsg.classList.add("hide");
    };
    
    // on blur: validate
    input.addEventListener('blur', function() {
      reset();
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          validMsg.classList.remove("hide");
        } else {
          input.classList.add("error");
          var errorCode = iti.getValidationError();
          errorMsg.innerHTML = errorMap[errorCode];
          errorMsg.classList.remove("hide");
        }
      }
    });
    
    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
});